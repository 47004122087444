import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { BlogCta } from "./helpdesk-problems-solutions";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image = require("../../../assets/img/blogs/itsmdt_blog_header.png");
const section_1 = require("../../../assets/img/blogs/itsmdt_blog_image_1.png");
const section_2 = require("../../../assets/img/blogs/itsmdt_blog_image_2.png");
const section_3 = require("../../../assets/img/blogs/itsmdt_blog_image_3.png");
const section_4 = require("../../../assets/img/blogs/itsmdt_blog_image_4.png");
const section_5 = require("../../../assets/img/blogs/itsmdt_blog_image_5.png");
const section_6 = require("../../../assets/img/blogs/itsmdt_blog_image_6.png");
const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Why do you need ITSM in your Digital Trasformation?"
        description="Get an effortless experience for your digital transformation by embracing ITSM transformation with Workativ, enabling you to build app workflow automation for your ITSM platforms."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt itsmdt_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img text-align-center ${
                  isMobile ? "" : "float-left"
                }`}
              >
                {isMobile ? (
                  <h1
                    className={`   ${
                      isMobile
                        ? "font-page-header-home-blog-gpt"
                        : "font-page-header-home-blog-gpt"
                    }`}
                  >
                    Why do you need ITSM in your Digital Trasformation?
                  </h1>
                ) : (
                  <h1
                    className={`   ${
                      isMobile
                        ? "font-page-header-home-blog-gpt"
                        : "font-page-header-home-blog-gpt"
                    }`}
                  >
                    Why do you need ITSM in
                    <br /> your Digital Trasformation?
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}
          {isMobile ? <Faq /> : null}
          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. What is ITSM?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. How does ITSM accelerate digital transformation?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Why is modernized ITSM key to your digital transformation?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. What are the areas in which ITSM acts as a catalyst for
                  digital transformation?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. What are the benefits of ITSM for digital transformation?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. The value addition of Workativ to your ITSM transformation
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Reminiscing the COVID-19 phase is visualizing those dire
                attempts to keep businesses running. The traditional approach to
                serving customers fell short of business expectations, which
                abruptly pushed for a paradigm shift in the existing operating
                model towards a more digitized strategy.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Businesses soon embraced cloud-native approaches for survival
                and to better meet customer needs. CIOs, CTOs, and CFOs aimed to
                foster business resilience and business continuity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                According to IBM’s Institute for Business Value,{" "}
                <a href="https://www.ibm.com/downloads/cas/4VMAVDNA">
                  72% of 5,000 C-suite IT leaders surveyed emphasized end-user
                  experience, whereas 68% of the respondents prioritized
                  business continuity.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This perhaps indicates the enterprise’s ambition to transform
                digitally. As IDC predicted that digital transformation spending
                will grow at a CAGR of 16.4% over the 2021-2025 forecast period,
                business leaders are poised to achieve holistic digital strategy
                across people, processes, technology, and governance.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                And{" "}
                <a href="https://www.idc.com/getdoc.jsp?containerId=prUS48372321">
                  many of the digital transformation priorities would be
                  enhancing operational objectives
                </a>{" "}
                around Human Resources, security & risk, Enterprise IT,
                accounting & finance, according to Craig Simpson, senior
                research manager at IDC.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Notably, digital transformation can benefit only if the critical
                mission is managed by agile ITSM practices.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This necessarily prioritizes the ITSM transformation as well in
                order to align with the digital transformation mission.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here’s why ITSM transformation is important to digital
                transformation aspects.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                What is ITSM?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Information Technology Service Management or ITSM defines the
                ability of an IT organization or, more specifically,
                infrastructure & operations (I&O)managers to{" "}
                <a href="https://www.gartner.com/en/information-technology/glossary/itssm-tools-it-service-support-management-tools">
                  facilitate end-to-end IT service delivery through robust
                  management of internal IT tasks and workflows.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A simpler definition of ITSM is making IT service delivery easy
                and fast through proper oversight of the implementation plan,
                tracking technology processes, and measuring the progress
                through workflow management.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                For organizations that are engaged in new application
                development, product testing, cloud strategy, and management of
                service level agreements, look for agile ITSM practices.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                By leveraging a robust ITSM tool, an IT organization can
                successfully oversee and facilitate,
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-3 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Incident management
                </li>{" "}
                <li className="font-section-normal-text-testimonials">
                  Service management
                </li>
                <li className="font-section-normal-text-testimonials">
                  Change management
                </li>
                <li className="font-section-normal-text-testimonials">
                  IT asset management, etc
                </li>
              </ul>
              <img src={section_1} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-chatbot-guide">
                  ITSM is the best component for a service desk in an
                  organization.
                </a>{" "}
                However, non-IT business processes also use ITSM to enable
                enterprise service management.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How does ITSM accelerate digital transformation?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Digital transformation aims to bring change to the forefront of
                organizational processes. But, change always does not mean
                process efficiency and user experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Any new addition of tools and workflows to the work processes,
                if not widely accepted, is unlikely to bring the business
                outcomes as IT leaders aspire from their digital transformation
                initiatives.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, suppose your business allocates a pool of new tools
                and technologies for your people. This is a change in the
                workplace. But what if they continue to deliver the same level
                of productivity without much difference in outcomes?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This requires a careful approach to tracking change management
                and inspiring user engagement.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                ITSM best practices help facilitate strategic digital
                transformation by enabling IT leaders to track and monitor the
                effectiveness of organization-wide change.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Viewed as similar to agile methodologies, ITSM can be a
                significant tool for CIOs to ‘Build, Measure, Learn, and
                Repeat.’ Greg Douglass, SENIOR MANAGING DIRECTOR – TECHNOLOGY
                STRATEGY & ADVISORY​, is of the opinion for the above statement.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                {" "}
                He also suggests that an operating model built on agile
                methodologies (like that of ITSM) can{" "}
                <a href="https://www.accenture.com/us-en/blogs/business-functions-blog/cio-digital-transformation">
                  bring on rapid value early rather than waiting for years for
                  digital transformation to complete.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, business is unlikely to tap essential benefits from
                digital transformation unless ITSM evolves from its traditional
                approach.{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/top-itsm-trends-2023">
                  ITSM transformation is key to facilitating digital
                  transformation,
                </a>{" "}
                so modernization of ITSM is significant.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why is modernized ITSM key to your digital transformation?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Just as the pandemic surged, the influx of digital tools was
                quite a norm. This pace of change wasn’t easy to handle for
                leaders.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The business complexity around data governance and rapid change
                in service management only add to more challenges.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The 2021 IBM CIO Study focuses on the importance of digital
                transformation for business continuity and operational
                resilience post-pandemic.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                According to CIOs reported in this study,{" "}
                <a href="https://www.ibm.com/downloads/cas/4VMAVDNA">
                  about 20%-40% of business processes have been automated.
                </a>
              </p>
              <h3 className="font-section-sub-header-small-home">
                Percentage of automation in various business processes{" "}
              </h3>
              <img src={section_2} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                Besides, IT budgets for new tools and technologies also
                increased.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Increase in technology investments
              </h3>
              <img src={section_3} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                When tools and applications are complex, adopting just a
                traditional ITSM practice won’t drive business efficiency and
                operations. A solid ITSM strategy can help businesses deliver
                services and make customers happy.
              </p>
              <div className="gen_ai_enter_para">
                <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                  “What customers expected from their technology services 5
                  years ago is light years away from their expectations today,
                  so the management of those services need to adapt and change,”
                </p>
                <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
                  - KPMG’s Mitch Kenfield
                </p>
              </div>
              <p className="font-section-normal-text-testimonials line-height-18">
                Kenfield’s view is still relevant today. And it is indeed
                important for ITSM to change or transform if CIOs look to
                achieve digital transformation at scale.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What are the areas in which ITSM acts as a catalyst for digital
                transformation?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                An ITSM-based operating model must encompass modern tools and
                applications to aid digital transformation across enterprises or
                SMBs.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Knowledge management in a modern way
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Knowledge management is a massive necessity for IT organizations
                to facilitate operational efficiency.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                But, knowledge management resources that lack the ability to
                provide context and instant help only compound business
                complexity rather than relieving employee stress.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  AITSM-powered knowledge management reads through the history
                  and updates its database with appropriate knowledge.
                </li>{" "}
                <li className="font-section-normal-text-testimonials">
                  Generative AI also makes it easy to improve the searchability
                  of appropriate knowledge articles to accelerate service
                  management through DIY attempts.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/conversational-ai-platform/dropbox-chatbot">
                    Multi-channel integration through MS Teams, Slack, or web
                    widgets
                  </a>{" "}
                  makes it easy for internal employees to achieve process
                  efficiency and restore service interruptions steadily.
                </li>
              </ul>
              <BlogCta
                ContentCta="Accelerate Your ITSM Digital Transformation."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <h3 className="font-section-sub-header-small-home ">
                AI in ITSM for incident management
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                The modern ITSM allows you to tap the potential of artificial
                intelligence, machine learning, and natural language processing.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                When combined, these technologies are best used to create
                automated workflows to handle incidents. As soon as an incident
                is logged, workflows get activated immediately to solve the
                issues rapidly.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                AI enables the categorization of incidents, prioritization,
                ticket creation, and ticket escalation to the right person who
                can handle it.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Modern ITSM platforms are easy to create automated workflows.
                The one critical aspect is that users are slow to adapt to the
                hard-core ITSM ecosystem, and incident resolution may be in
                conflict.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                A{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide">
                  self-service portal through ITSM chatbot integration in your
                  collaboration channel
                </a>{" "}
                delivers faster mean time to response or MTR.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                As a result, IT businesses can see a number of benefits,
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-3 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  A reduced number of ticket volumes
                </li>{" "}
                <li className="font-section-normal-text-testimonials">
                  Better insights and reporting to predict future issues and
                  prepare for uncertainties
                </li>
                <li className="font-section-normal-text-testimonials">
                  Real-time SLA management
                </li>
                <li className="font-section-normal-text-testimonials">
                  Vendor-relationship management
                </li>
              </ul>
              <img src={section_4} className="blog_image_top_bt" />
              <h3 className="font-section-sub-header-small-home ">
                IT Asset management
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                IT assets are critical to continuing business operations.
                Monitoring every application's operational capability and
                serviceability becomes a significant aspect of business
                continuity. <a href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide">ITSM digital transformation is better than the
                traditional approach</a> of notifying of the end of SLA or
                suggesting predictive maintenance for IT assets to facilitate
                active IT asset management.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                AI-powered automated workflows help IT leaders save time by
                reducing the mundane job of monitoring IT assets manually while
                resolving more critical issues with ease.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Robust service management
              </h3>

              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                It is evident that IT organizations struggle with 80% of
                repetitive tasks at the service desk. The most mundane processes
                employees seek help are,
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-3 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Password resets
                </li>{" "}
                <li className="font-section-normal-text-testimonials">
                  Device provisioning
                </li>
                <li className="font-section-normal-text-testimonials">
                  Unlock accounts
                </li>
                <li className="font-section-normal-text-testimonials">
                  Software installs
                </li>
              </ul>
              <img src={section_5} className="blog_image_top_bt" />
              <p class="font-section-normal-text-testimonials line-height-18">
                Since traditional ITSM provides less visibility across the
                ticket history and status, IT agents face ticket fatigue more
                often due to the scenario of repeatedly handling the same
                ticket.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                AI transformation ITSM reduces the probability of errors and
                increases process efficiency by facilitating autonomous
                self-serve capabilities.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Say a new hire needs a new laptop and necessary tools and
                applications. Through the{" "}
                <a href="https://workativ.com/conversational-ai-platform/aws-chatbot">
                  automation of Access Identity Management,
                </a>{" "}
                an automatic software installation can be done, whereas new
                tools and application provisioning can be made easy.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Change management through data-driven decisions
              </h3>

              <p class="font-section-normal-text-testimonials line-height-18">
                Success in digital transformation is only possible with proper
                change management.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Change may be rigid when a new tool or process is implemented
                organization-wide. An AI-powered ITSM practice helps harness
                data insights for in-depth analysis and to implement the
                strategy that encourages swift adaptation to the change.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                In an interview with Accenture,{" "}
                <a href="https://www.accenture.com/us-en/blogs/business-functions-blog/cio-digital-transformation">
                  Greg Douglass suggests that organizations must focus on
                  increasing their employees' talent quotient when there is too
                  much assimilation of tools.
                </a>
                Reskilling is the best option. By creating an appropriate LMS
                for your ITSM, you can better contribute to employee
                optimization and make them feel comfortable with the change.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What are the benefits of ITSM for digital transformation?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                Digital transformation is as bland as just a strategy if not
                appropriately implemented and monitored. AI ITSM transformation
                facilitates the business objectives of IT leaders. There are
                immense possibilities for ITSM transformation to drive
                successful digital transformation.
              </p>

              <h3 className="font-section-sub-header-small-home ">
                Operational efficiency through automation of internal workflows
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Automated workflows accelerate operational efficiency across IT,
                HR, marketing, supply chain, and ITOps, enabling end-to-end IT
                service delivery and expediting enhanced customer experience.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                With workflows designed and implemented in ITSM platforms, they
                tend to reduce additional effort and time to take care of
                critical business operations such as
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-3 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Employee onboarding
                </li>{" "}
                <li className="font-section-normal-text-testimonials">
                  Leave management
                </li>
                <li className="font-section-normal-text-testimonials">
                  Document management and approval
                </li>
                <li className="font-section-normal-text-testimonials">
                  Device provisioning
                </li>
                <li className="font-section-normal-text-testimonials">
                  Invoice processing and payment
                </li>
                <li className="font-section-normal-text-testimonials">
                  Procurement in the supply chain
                </li>
              </ul>
              <img src={section_6} className="blog_image_top_bt" />
              <p class="font-section-normal-text-testimonials line-height-18">
                For example,{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  AI-powered chatbots
                </a>{" "}
                reduce customer friction as well as improve productivity for
                internal employees.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                According to a{" "}
                <a href="https://www.bloomberg.com/news/articles/2023-04-24/generative-ai-boosts-worker-productivity-14-new-study-finds?utm_source=www.theneurondaily.com&utm_medium=newsletter&utm_campaign=goodbye-long-holds&leadSource=uverify%20wall#xj4y7vzkg">
                  report by Standford and MIT, generative AI chatbots helped
                  boost workers’ productivity
                </a>{" "}
                by 14% for one tech company.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Reducing operational costs
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                A modern ITSM infrastructure can aid cost efficiency and
                savings. With reduced repetitive tickets and effective
                end-to-end ticket lifecycle management, IT agents can reduce 80%
                of repetitive tasks.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Self-service enablement and agent handover through natural
                language processing that understands the intent and derives
                knowledge extractions drive cost efficiency.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                It also means that agents are less involved in ticket handling,
                which saves agent utilization and ticket costs.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Therefore, companies optimizing the AITSM platform save a huge
                amount on operational budget and reduce the impact on the bottom
                line. Also, investment in AI ITSM transformation pays off in the
                long run and offers better ROI.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Ease of access to agile ITSM
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                ITSM practices can be inflexible and inconvenient for your IT
                teams if they still involve the traditional approach, meaning if
                they are not agile.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Agile ITSM is a need to remain competitive and bring a
                collaborative approach to DevOps and IT practices.
              </p>
              <div className="gen_ai_enter_para">
                <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                  “By 2023, 80% of ITSM teams that have not adopted an agile
                  approach will find their ITSM practices are ignored or
                  bypassed as a result of more agile ways of working being
                  adopted elsewhere in the organization.”
                </p>
                <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
                  - Gartner
                </p>
              </div>
              <p className="font-section-normal-text-testimonials line-height-18">
                AI transformation for IT service management ensures
                Infrastructure & Operations leaders implement agile
                methodologies and best practices for software development and
                DevOps with flexible, collaborative, and iterative approaches
                across the ITSM platform. That being said, agile ITSM also
                brings the top practices mentioned in the ITIL-4 to help
                streamline work processes with automation, facilitate
                customer-centric service management, etc.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                The value addition of Workativ to your ITSM transformation
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                Competitiveness is being able to adapt and conform to the
                changes steadily, so you can accomplish a complete digital
                transformation by embracing the ITSM transformation.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Enterprise use cases are built around the necessity of IT
                service delivery, wherein ITSM complements the enterprise
                objectives.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                To give you an effortless experience for your digital
                transformation, Workativ makes ITSM more agile and streamlined
                by developing no-code conversational AI chatbots for your ITSM
                platforms.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Irrespective of your choice of platforms, be it ServiceNow,
                Jira, HaloITSM, Ivanti, SysAid, or InvGate, Workativ makes it
                super easy for you to{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  build conversational AI chatbots
                </a>{" "}
                and sync them inside collaborative channels like MS Teams or
                Slack. This gives uninterrupted flexibility to automate app
                workflows for those ITSM platforms and auto-resolve issues at
                scale without spending on reskilling or talent development.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Embrace ITSM transformation with Workativ and be prepared to
                drive digital transformation success.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Have ITSM transformation in mind?{" "}
                <a href="/conversational-ai-platform/demo">
                  Set up a demo call{" "}
                </a>
                with Workativ Sales Team today!
              </p>
            </div>
            <Faq />
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. What is ITSM?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. How does ITSM accelerate digital transformation?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Why is modernized ITSM key to your digital
                    transformation?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. What are the areas in which ITSM acts as a catalyst for
                    digital transformation?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. What are the benefits of ITSM for digital transformation?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. The value addition of Workativ to your ITSM
                    transformation
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Reminiscing the COVID-19 phase is visualizing those dire
                attempts to keep businesses running. The traditional approach to
                serving customers fell short of business expectations, which
                abruptly pushed for a paradigm shift in the existing operating
                model towards a more digitized strategy.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Businesses soon embraced cloud-native approaches for survival
                and to better meet customer needs. CIOs, CTOs, and CFOs aimed to
                foster business resilience and business continuity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                According to IBM’s Institute for Business Value,{" "}
                <a href="https://www.ibm.com/downloads/cas/4VMAVDNA">
                  72% of 5,000 C-suite IT leaders surveyed emphasized end-user
                  experience, whereas 68% of the respondents prioritized
                  business continuity.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This perhaps indicates the enterprise’s ambition to transform
                digitally. As IDC predicted that digital transformation spending
                will grow at a CAGR of 16.4% over the 2021-2025 forecast period,
                business leaders are poised to achieve holistic digital strategy
                across people, processes, technology, and governance.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                And{" "}
                <a href="https://www.idc.com/getdoc.jsp?containerId=prUS48372321">
                  many of the digital transformation priorities would be
                  enhancing operational objectives
                </a>{" "}
                around Human Resources, security & risk, Enterprise IT,
                accounting & finance, according to Craig Simpson, senior
                research manager at IDC.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Notably, digital transformation can benefit only if the critical
                mission is managed by agile ITSM practices.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This necessarily prioritizes the ITSM transformation as well in
                order to align with the digital transformation mission.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here’s why ITSM transformation is important to digital
                transformation aspects.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                What is ITSM?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Information Technology Service Management or ITSM defines the
                ability of an IT organization or, more specifically,
                infrastructure & operations (I&O)managers to{" "}
                <a href="https://www.gartner.com/en/information-technology/glossary/itssm-tools-it-service-support-management-tools">
                  facilitate end-to-end IT service delivery through robust
                  management of internal IT tasks and workflows.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A simpler definition of ITSM is making IT service delivery easy
                and fast through proper oversight of the implementation plan,
                tracking technology processes, and measuring the progress
                through workflow management.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                For organizations that are engaged in new application
                development, product testing, cloud strategy, and management of
                service level agreements, look for agile ITSM practices.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                By leveraging a robust ITSM tool, an IT organization can
                successfully oversee and facilitate,
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-3 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Incident management
                </li>{" "}
                <li className="font-section-normal-text-testimonials">
                  Service management
                </li>
                <li className="font-section-normal-text-testimonials">
                  Change management
                </li>
                <li className="font-section-normal-text-testimonials">
                  IT asset management, etc
                </li>
              </ul>
              <img src={section_1} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-chatbot-guide">
                  ITSM is the best component for a service desk in an
                  organization.
                </a>{" "}
                However, non-IT business processes also use ITSM to enable
                enterprise service management.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How does ITSM accelerate digital transformation?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Digital transformation aims to bring change to the forefront of
                organizational processes. But, change always does not mean
                process efficiency and user experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Any new addition of tools and workflows to the work processes,
                if not widely accepted, is unlikely to bring the business
                outcomes as IT leaders aspire from their digital transformation
                initiatives.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, suppose your business allocates a pool of new tools
                and technologies for your people. This is a change in the
                workplace. But what if they continue to deliver the same level
                of productivity without much difference in outcomes?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This requires a careful approach to tracking change management
                and inspiring user engagement.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                ITSM best practices help facilitate strategic digital
                transformation by enabling IT leaders to track and monitor the
                effectiveness of organization-wide change.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Viewed as similar to agile methodologies, ITSM can be a
                significant tool for CIOs to ‘Build, Measure, Learn, and
                Repeat.’ Greg Douglass, SENIOR MANAGING DIRECTOR – TECHNOLOGY
                STRATEGY & ADVISORY​, is of the opinion for the above statement.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                {" "}
                He also suggests that an operating model built on agile
                methodologies (like that of ITSM) can{" "}
                <a href="https://www.accenture.com/us-en/blogs/business-functions-blog/cio-digital-transformation">
                  bring on rapid value early rather than waiting for years for
                  digital transformation to complete.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, business is unlikely to tap essential benefits from
                digital transformation unless ITSM evolves from its traditional
                approach.{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/top-itsm-trends-2023">
                  ITSM transformation is key to facilitating digital
                  transformation,
                </a>{" "}
                so modernization of ITSM is significant.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why is modernized ITSM key to your digital transformation?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Just as the pandemic surged, the influx of digital tools was
                quite a norm. This pace of change wasn’t easy to handle for
                leaders.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The business complexity around data governance and rapid change
                in service management only add to more challenges.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The 2021 IBM CIO Study focuses on the importance of digital
                transformation for business continuity and operational
                resilience post-pandemic.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                According to CIOs reported in this study,{" "}
                <a href="https://www.ibm.com/downloads/cas/4VMAVDNA">
                  about 20%-40% of business processes have been automated.
                </a>
              </p>
              <h3 className="font-section-sub-header-small-home">
                Percentage of automation in various business processes{" "}
              </h3>
              <img src={section_2} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                Besides, IT budgets for new tools and technologies also
                increased.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Increase in technology investments
              </h3>
              <img src={section_3} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                When tools and applications are complex, adopting just a
                traditional ITSM practice won’t drive business efficiency and
                operations. A solid ITSM strategy can help businesses deliver
                services and make customers happy.
              </p>
              <div className="gen_ai_enter_para">
                <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                  “What customers expected from their technology services 5
                  years ago is light years away from their expectations today,
                  so the management of those services need to adapt and change,”
                </p>
                <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
                  - KPMG’s Mitch Kenfield
                </p>
              </div>
              <p className="font-section-normal-text-testimonials line-height-18">
                Kenfield’s view is still relevant today. And it is indeed
                important for ITSM to change or transform if CIOs look to
                achieve digital transformation at scale.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What are the areas in which ITSM acts as a catalyst for digital
                transformation?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                An ITSM-based operating model must encompass modern tools and
                applications to aid digital transformation across enterprises or
                SMBs.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Knowledge management in a modern way
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Knowledge management is a massive necessity for IT organizations
                to facilitate operational efficiency.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                But, knowledge management resources that lack the ability to
                provide context and instant help only compound business
                complexity rather than relieving employee stress.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  AITSM-powered knowledge management reads through the history
                  and updates its database with appropriate knowledge.
                </li>{" "}
                <li className="font-section-normal-text-testimonials">
                  Generative AI also makes it easy to improve the searchability
                  of appropriate knowledge articles to accelerate service
                  management through DIY attempts.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/conversational-ai-platform/dropbox-chatbot">
                    Multi-channel integration through MS Teams, Slack, or web
                    widgets
                  </a>{" "}
                  makes it easy for internal employees to achieve process
                  efficiency and restore service interruptions steadily.
                </li>
              </ul>
              <BlogCta
                ContentCta="Accelerate Your ITSM Digital Transformation."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <h3 className="font-section-sub-header-small-home ">
                AI in ITSM for incident management
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                The modern ITSM allows you to tap the potential of artificial
                intelligence, machine learning, and natural language processing.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                When combined, these technologies are best used to create
                automated workflows to handle incidents. As soon as an incident
                is logged, workflows get activated immediately to solve the
                issues rapidly.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                AI enables the categorization of incidents, prioritization,
                ticket creation, and ticket escalation to the right person who
                can handle it.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Modern ITSM platforms are easy to create automated workflows.
                The one critical aspect is that users are slow to adapt to the
                hard-core ITSM ecosystem, and incident resolution may be in
                conflict.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                A{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide">
                  self-service portal through ITSM chatbot integration in your
                  collaboration channel
                </a>{" "}
                delivers faster mean time to response or MTR.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                As a result, IT businesses can see a number of benefits,
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-3 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  A reduced number of ticket volumes
                </li>{" "}
                <li className="font-section-normal-text-testimonials">
                  Better insights and reporting to predict future issues and
                  prepare for uncertainties
                </li>
                <li className="font-section-normal-text-testimonials">
                  Real-time SLA management
                </li>
                <li className="font-section-normal-text-testimonials">
                  Vendor-relationship management
                </li>
              </ul>
              <img src={section_4} className="blog_image_top_bt" />
              <h3 className="font-section-sub-header-small-home ">
                IT Asset management
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                IT assets are critical to continuing business operations.
                Monitoring every application's operational capability and
                serviceability becomes a significant aspect of business
                continuity. <a href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide">ITSM digital transformation is better than the
                traditional approach</a> of notifying of the end of SLA or
                suggesting predictive maintenance for IT assets to facilitate
                active IT asset management.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                AI-powered automated workflows help IT leaders save time by
                reducing the mundane job of monitoring IT assets manually while
                resolving more critical issues with ease.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Robust service management
              </h3>

              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                It is evident that IT organizations struggle with 80% of
                repetitive tasks at the service desk. The most mundane processes
                employees seek help are,
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-3 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Password resets
                </li>{" "}
                <li className="font-section-normal-text-testimonials">
                  Device provisioning
                </li>
                <li className="font-section-normal-text-testimonials">
                  Unlock accounts
                </li>
                <li className="font-section-normal-text-testimonials">
                  Software installs
                </li>
              </ul>
              <img src={section_5} className="blog_image_top_bt" />
              <p class="font-section-normal-text-testimonials line-height-18">
                Since traditional ITSM provides less visibility across the
                ticket history and status, IT agents face ticket fatigue more
                often due to the scenario of repeatedly handling the same
                ticket.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                AI transformation ITSM reduces the probability of errors and
                increases process efficiency by facilitating autonomous
                self-serve capabilities.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Say a new hire needs a new laptop and necessary tools and
                applications. Through the{" "}
                <a href="https://workativ.com/conversational-ai-platform/aws-chatbot">
                  automation of Access Identity Management,
                </a>{" "}
                an automatic software installation can be done, whereas new
                tools and application provisioning can be made easy.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Change management through data-driven decisions
              </h3>

              <p class="font-section-normal-text-testimonials line-height-18">
                Success in digital transformation is only possible with proper
                change management.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Change may be rigid when a new tool or process is implemented
                organization-wide. An AI-powered ITSM practice helps harness
                data insights for in-depth analysis and to implement the
                strategy that encourages swift adaptation to the change.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                In an interview with Accenture,{" "}
                <a href="https://www.accenture.com/us-en/blogs/business-functions-blog/cio-digital-transformation">
                  Greg Douglass suggests that organizations must focus on
                  increasing their employees' talent quotient when there is too
                  much assimilation of tools.
                </a>
                Reskilling is the best option. By creating an appropriate LMS
                for your ITSM, you can better contribute to employee
                optimization and make them feel comfortable with the change.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What are the benefits of ITSM for digital transformation?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                Digital transformation is as bland as just a strategy if not
                appropriately implemented and monitored. AI ITSM transformation
                facilitates the business objectives of IT leaders. There are
                immense possibilities for ITSM transformation to drive
                successful digital transformation.
              </p>

              <h3 className="font-section-sub-header-small-home ">
                Operational efficiency through automation of internal workflows
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Automated workflows accelerate operational efficiency across IT,
                HR, marketing, supply chain, and ITOps, enabling end-to-end IT
                service delivery and expediting enhanced customer experience.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                With workflows designed and implemented in ITSM platforms, they
                tend to reduce additional effort and time to take care of
                critical business operations such as
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-3 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Employee onboarding
                </li>{" "}
                <li className="font-section-normal-text-testimonials">
                  Leave management
                </li>
                <li className="font-section-normal-text-testimonials">
                  Document management and approval
                </li>
                <li className="font-section-normal-text-testimonials">
                  Device provisioning
                </li>
                <li className="font-section-normal-text-testimonials">
                  Invoice processing and payment
                </li>
                <li className="font-section-normal-text-testimonials">
                  Procurement in the supply chain
                </li>
              </ul>
              <img src={section_6} className="blog_image_top_bt" />
              <p class="font-section-normal-text-testimonials line-height-18">
                For example,{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  AI-powered chatbots
                </a>{" "}
                reduce customer friction as well as improve productivity for
                internal employees.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                According to a{" "}
                <a href="https://www.bloomberg.com/news/articles/2023-04-24/generative-ai-boosts-worker-productivity-14-new-study-finds?utm_source=www.theneurondaily.com&utm_medium=newsletter&utm_campaign=goodbye-long-holds&leadSource=uverify%20wall#xj4y7vzkg">
                  report by Standford and MIT, generative AI chatbots helped
                  boost workers’ productivity
                </a>{" "}
                by 14% for one tech company.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Reducing operational costs
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                A modern ITSM infrastructure can aid cost efficiency and
                savings. With reduced repetitive tickets and effective
                end-to-end ticket lifecycle management, IT agents can reduce 80%
                of repetitive tasks.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Self-service enablement and agent handover through natural
                language processing that understands the intent and derives
                knowledge extractions drive cost efficiency.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                It also means that agents are less involved in ticket handling,
                which saves agent utilization and ticket costs.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Therefore, companies optimizing the AITSM platform save a huge
                amount on operational budget and reduce the impact on the bottom
                line. Also, investment in AI ITSM transformation pays off in the
                long run and offers better ROI.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Ease of access to agile ITSM
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                ITSM practices can be inflexible and inconvenient for your IT
                teams if they still involve the traditional approach, meaning if
                they are not agile.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Agile ITSM is a need to remain competitive and bring a
                collaborative approach to DevOps and IT practices.
              </p>
              <div className="gen_ai_enter_para">
                <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                  “By 2023, 80% of ITSM teams that have not adopted an agile
                  approach will find their ITSM practices are ignored or
                  bypassed as a result of more agile ways of working being
                  adopted elsewhere in the organization.”
                </p>
                <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
                  - Gartner
                </p>
              </div>
              <p className="font-section-normal-text-testimonials line-height-18">
                AI transformation for IT service management ensures
                Infrastructure & Operations leaders implement agile
                methodologies and best practices for software development and
                DevOps with flexible, collaborative, and iterative approaches
                across the ITSM platform. That being said, agile ITSM also
                brings the top practices mentioned in the ITIL-4 to help
                streamline work processes with automation, facilitate
                customer-centric service management, etc.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                The value addition of Workativ to your ITSM transformation
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                Competitiveness is being able to adapt and conform to the
                changes steadily, so you can accomplish a complete digital
                transformation by embracing the ITSM transformation.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Enterprise use cases are built around the necessity of IT
                service delivery, wherein ITSM complements the enterprise
                objectives.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                To give you an effortless experience for your digital
                transformation, Workativ makes ITSM more agile and streamlined
                by developing no-code conversational AI chatbots for your ITSM
                platforms.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Irrespective of your choice of platforms, be it ServiceNow,
                Jira, HaloITSM, Ivanti, SysAid, or InvGate, Workativ makes it
                super easy for you to{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  build conversational AI chatbots
                </a>{" "}
                and sync them inside collaborative channels like MS Teams or
                Slack. This gives uninterrupted flexibility to automate app
                workflows for those ITSM platforms and auto-resolve issues at
                scale without spending on reskilling or talent development.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Embrace ITSM transformation with Workativ and be prepared to
                drive digital transformation success.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Have ITSM transformation in mind?{" "}
                <a href="/conversational-ai-platform/demo">
                  Set up a demo call{" "}
                </a>
                with Workativ Sales Team today!
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>

      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/service-desk-chatbot-guide"
              className="font-section-normal-text-testimonials"
            >
              Service Desk Chatbot Automation: A Complete Guide
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide"
              className="font-section-normal-text-testimonials"
            >
              ITSM CHATBOT: Use Cases and Benefits Explained
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/service-desk-chatbot-guide"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              A Complete Guide: Service Desk Chatbot Automation
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
export function Faq() {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <React.Fragment>
      <div
        className={`mob_accordian mob_accordian_faq faq_blog_upd ${
          isMobile ? "mt-3" : "mt-0"
        }`}
      >
        <section className="accordian_landing trial_accordian_landing ">
          {isMobile ? (
            <div className="container">
              <h2
                className={`font-section-sub-header ${
                  isMobile ? "text-align-center" : "text-align-left"
                }`}
              >
                HR Help Desk Frequently Asked Questions
              </h2>
              <div className="col-12 pl-0 faq_landing">
                <Accordion
                  id="accordion"
                  className="accordion pl-0"
                  preExpanded={["a"]}
                >
                  <div className="accordion-wrapper pricing_faq">
                    {faqData.map((data) => (
                      <AccordionItem uuid={data.uuid}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            {data.header}
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p
                            dangerouslySetInnerHTML={{ __html: data.paragraph }}
                            className="font-section-normal-text line-height-18"
                          />
                          {data.paragraph1 && (
                            <p
                              className="para_2_pricing font-section-normal-text line-height-18"
                              dangerouslySetInnerHTML={{
                                __html: data.paragraph1,
                              }}
                            />
                          )}
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))}
                  </div>
                </Accordion>
              </div>
            </div>
          ) : (
            <>
              <h2
                className={`font-section-sub-header ${
                  isMobile ? "text-align-center" : "text-align-left"
                }`}
              >
                FAQs on ITSM-powered digital transformation
              </h2>
              <div className="col-12 pl-0 faq_landing">
                <Accordion
                  id="accordion"
                  className="accordion pl-0"
                  preExpanded={["a"]}
                >
                  <div className="accordion-wrapper pricing_faq">
                    {faqData.map((data) => (
                      <AccordionItem uuid={data.uuid}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            {data.header}
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p
                            dangerouslySetInnerHTML={{ __html: data.paragraph }}
                            className="font-section-normal-text line-height-18"
                          />
                          {data.paragraph1 && (
                            <p
                              className="para_2_pricing font-section-normal-text line-height-18"
                              dangerouslySetInnerHTML={{
                                __html: data.paragraph1,
                              }}
                            />
                          )}
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))}
                  </div>
                </Accordion>
              </div>
            </>
          )}
        </section>
      </div>
    </React.Fragment>
  );
}
export const faqData = [
  {
    header:
      "What should be the correct approach to enabling ITSM transformation?",
    paragraph:
      "The world of IT is evolving at a rapid pace. It shouldn’t be like that you implement it and then forget it. A continuous tweak based on the changes that come up effectively facilitates IT service delivery in real-time and prevents any future impact on service management. ",
    active: "active",
    uuid: "a",
  },
  {
    header: "Is ITSM built to promote digital transformation? ",
    paragraph:
      "ITSM practices are crucial to enabling digital transformation in the workplace, especially for IT organizations. However, traditional ITSM lacks the necessary advancement and sophistication in capabilities to conform to the ITIL4 framework and enable digital transformation success. ITSM transformation is significantly important for IT leaders to nurture enhanced ITSM experience for internal employees. ",
  },
  {
    header:
      "What could be the budget-friendly way to leverage ITSM in digital transformation? ",
    paragraph:
      "Generally, ITSM infrastructure does not provide an easy learning curve for employees. To make ITSM practice widely accepted in your organization, bring your entire ITSM functionalities inside collaborative channels through app workflow automation.<a href=`https://workativ.com/contact-us`>Talk to Workativ</a> to implement the easiest way of managing IT service delivery today. ",
  },
];
